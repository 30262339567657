<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getTaxReport"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <CRow>
            <CCol sm="12" lg="12">
              <div class="row">
                <div class="col-md-10 col-8 col-sm-10 d-inline-flex align-items-center">
                <h2 id="taxTitle" class="mb-0 font-weight-normal">
                  {{ $t('report.txtSalesTax') }}
                </h2>
                <HelpButton :isHeader="false" class="ml-2"></HelpButton>
                </div>
                <div
                  v-if="isExport"
                  class="col-md-2 col-sm-3 col-4 text-right text-success"
                >
                  <CButton id="taxExportButton" v-on:click="exportTaxReport()" block color="info">
                    <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
                  </CButton>
                </div>
              </div>
              <show-date></show-date>
              <hr />
              <DataTable
                 id="taxDatatable"
                :items="items"
                :fields="fields"
                :loading="loading"
                hover
                border
                style="white-space: nowrap"
              >
              </DataTable>
              <br />
              <CCol sm="4" lg="4">
                <div class="row h6">
                  <div class="col-md-6 font-weight-normal">
                    {{ $t('billAmount') }}
                  </div>
                  <div class="col-md-6 text-right font-weight-normal text-dark">
                    {{ bill }} {{ $t('bill') }}
                  </div>
                </div>
                <div class="row h6">
                  <div class="col-md-6 font-weight-normal">
                    {{ $t('totalAmount') }}
                  </div>
                  <div class="col-md-6 text-right font-weight-normal text-dark">{{ grandTotal }}</div>
                </div>
                <div class="row h6">
                  <div class="col-md-6 font-weight-normal">
                    {{ $t('netTotalNonVAT') }}
                  </div>
                  <div class="col-md-6 text-right font-weight-normal text-dark">{{ nonVat }}</div>
                </div>
                <div class="row h6">
                  <div class="col-md-6 font-weight-normal">
                    {{ $t('netAmountVATTotal') }}
                  </div>
                  <div class="col-md-6 text-right font-weight-normal text-dark">{{ beforeVat }}</div>
                </div>
                <div class="row h6">
                  <div class="col-md-6 font-weight-normal">
                    {{ $t('vatAmount') }}
                  </div>
                  <div class="col-md-6 text-right font-weight-normal text-dark">{{ vat }}</div>
                </div>
              </CCol>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '../../services/service'
import report from '../../services/report'
import util from '../../util/util'
import DataTable from '../../containers/DataTable'
import permis from '@/util/permission'
import HelpButton from '../../containers/HelpButton.vue'

export default {
  components: {
    DataTable,
    HelpButton
  },
  data() {
    return {
      data: [],
      detail: [],
      summary: [],
      loadingButton: true,
    }
  },
  computed: {
    ...mapGetters(['shops', 'date', 'users']),
    isPermission() {
      return permis.findPermissionRead('report', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    grandTotal() {
      let grandTotal = this.summary.grandTotal
      if (grandTotal) {
        return util.convertCurrency(grandTotal)
      } else {
        return '0.00'
      }
    },
    nonVat() {
      let nonVat = this.summary.nonVat
      if (nonVat) {
        return util.convertCurrency(nonVat)
      } else {
        return '0.00'
      }
    },
    beforeVat() {
      let beforeVat = this.summary.beforeVat
      if (beforeVat) {
        return util.convertCurrency(beforeVat)
      } else {
        return '0.00'
      }
    },
    vat() {
      let vat = this.summary.vat
      if (vat) {
        return util.convertCurrency(vat)
      } else {
        return '0.00'
      }
    },
    bill() {
      let bill = this.summary.bill
      if (bill) {
        return util.convertCurrency(bill)
      } else {
        return '0'
      }
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        { key: 'date', label: this.$i18n.t('date'), _classes: 'font-weight-normal text-dark' },
        { key: 'firstInvoiceNO', label: this.$i18n.t('firstInvoiceNO'), _classes: 'font-weight-normal text-dark' },
        { key: 'lastInvoiceNO', label: this.$i18n.t('lastInvoiceNO'), _classes: 'font-weight-normal  text-dark'},
        {
          key: 'nonVat',
          label: this.$i18n.t('netTotalNonVAT'),
          _classes: 'text-right text-dark font-weight-normal',
        },
        {
          key: 'beforeVat',
          label: this.$i18n.t('netAmountVATTotal'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'vat',
          label: this.$i18n.t('vatAmount'),
          _classes: 'text-right font-weight-normal text-dark',
        },
        {
          key: 'grandTotal',
          label: this.$i18n.t('grandTotal'),
          _classes: 'text-right font-weight-normal text-dark',
        },
      ]
    },
    items() {
      let detail = this.detail
      let data = []
      let detailCount = 0

      if (detail === undefined) {
        detailCount = 0
      } else {
        detailCount = detail.length
      }

      for (let i = 0; i < detailCount; i++) {
        data.push({
          date: detail[i].date,
          firstInvoiceNO: detail[i].firstInvoiceNO,
          lastInvoiceNO: detail[i].lastInvoiceNO,
          nonVat: util.convertCurrency(detail[i].nonVat),
          beforeVat: util.convertCurrency(detail[i].beforeVat),
          vat: util.convertCurrency(detail[i].vat),
          grandTotal: util.convertCurrency(detail[i].grandTotal),
        })
      }
      return data
    },
  },
  created() {
    this.getTaxReport()
  },
  methods: {
    ...util,
    getTaxReport() {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }

      const headers = {
        shopObjectId: shopObjectId,
      }
      this.loading = true
      axios({
        url: '/receipt/v1.0/gettaxreport/' + uid + '/data',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          this.detail = res.data.data.dates
          this.summary = res.data.data.summary
          this.loading = false
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportTaxReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      }

      const headers = {
        shopObjectId: shopObjectId,
      }

      report({
        url: '/receipt/v1.0/gettaxreport/' + uid + '/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานสรุปภาษีขาย.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
